/* global $ */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { getRegistryTypeFormat } from "../../utils/registryDateUtils";
import ViewGiftModal from "../../components/dashboard/modal/ViewGiftModal";
import EditGiftModal from "../../components/dashboard/modal/EditGiftModal";
import PersonalGiftModal from "../../components/dashboard/modal/PersonalGiftModal";
import EditCategoriesModal from "../../components/dashboard/modal/EditCategoriesModal";
import ActivateAccountModal from "../../components/dashboard/modal/ActivateAccountModal";
import ShareRegistryModal from "../../components/dashboard/modal/ShareRegistryModal";
import PurchaserDetailsModal from "../../components/dashboard/modal/PurchaserDetailsModal";
import UnavailableModal from "../../components/dashboard/modal/UnavailableModal";
import { getRegistryCredentials, clearRegistryCredentials } from '../../utils/registryUtils';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMediaQuery } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import api from "../../services/api";
import dayjs from 'dayjs';

const DashboardPage = () => {
  const navigate = useNavigate();
  const isPortrait = useMediaQuery('(max-width:767px)');
  const [registryItems, setRegistryItems] = useState([]);
  const [registryDetails, setRegistryDetails] = useState(null);
  const [registryItemIdToDelete, setRegistryItemIdToDelete] = useState();
  const [openCalender, setOpenCalendar] = useState(false);
  const [answerDate, setAnswerDate] = useState(dayjs(new Date()));
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [unavailableItemId, setUnavailableItemId] = useState(null);
  const [unavailableItemUrl, setUnavailableItemUrl] = useState(null);
  const [purchasedItemId, setPurchasedItemId] = useState(null);
  const [isGridMode, setIsGridMode] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { accessToken, registryTypeId, registryId, fromMobileApp } = getRegistryCredentials();
  const [filterOptions, setFilterOptions] = useState({
    platform_name: '',
    item_category_id: '',
    purchased: '',
    sort_by: '',
  });

  window.handleSharedText = async (data) => {
    localStorage.setItem('productUrl', data.url);
    localStorage.setItem('rawHTML', data.response);
    navigate("/add-gift-modal");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleGiftView = (e) => {
      const button = e.target.closest('button');
      
      if (button.classList.contains('list-btn')) {
        $('.full-list ul').addClass('view-list').removeClass('grid');
        $('.product-img img').css({'height': '140px', 'objectFit': 'contain'});
      } else if (button.classList.contains('grid')) {
        $('.full-list ul').addClass('grid').removeClass('view-list');
        $('.product-img img').css({'height': '250px','object-fit': 'contain'});
      }
    };
  
    $(document).on('click', '.grid-buttons button', handleGiftView);
  }, []);

  const handleToggleViewMode = (isGrid) => {
    setIsGridMode(isGrid);
  };

  useEffect(() => {
    fetchRegistryDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchRegistryItems();
    // eslint-disable-next-line
  }, [filterOptions]);

  const fetchRegistryDetails = async () => {
    try {
      const response = await api.getDashboardDetails(accessToken, registryId);
      setRegistryDetails(response);
      setLoading(false); 
    } catch (error) {
      if (error.response?.data?.message === "Unauthenticated.") {
        clearRegistryCredentials();
        if (fromMobileApp) {
          navigate('/m-homepage');
        } else {
          navigate('/');
        }
      } else {
        console.error("Error fetching registry details:", error.message);
        setLoading(true);
      }
    }
  };  

  const fetchRegistryItems = async () => {
    try {
      const filteredOptions = {};
      if (filterOptions.purchased !== '') {
        filteredOptions.purchased = filterOptions.purchased;
      }
      if (filterOptions.item_category_id !== '') {
        filteredOptions.item_category_id = filterOptions.item_category_id;
      }
      if (filterOptions.platform_name !== '') {
        filteredOptions.platform_name = filterOptions.platform_name;
      }
      if (filterOptions.sort_by !== '') {
        filteredOptions.sort_by = filterOptions.sort_by;
      }
  
      const filteredItems = await api.getRegistryItems(accessToken, registryId, filteredOptions);
      setRegistryItems(filteredItems.items);
      setLoading(false);
    } catch (error) {
      if (error.response?.data?.message === "Unauthenticated.") {
        clearRegistryCredentials();
        if (fromMobileApp) {
          navigate('/m-homepage');
        } else {
          navigate('/');
        }
      } else {
        console.error('Error fetching filtered and sorted items:', error);
        showToast("Failed to load gifts", 'error');
        setLoading(true);
      }
    }
  };

  const getUserAnswerByIndex = (userAnswers, index) => {
    return userAnswers[index]?.question_answer || '';
  };

  const getIndexForRegistry = (registryTypeId) => {
    if (registryTypeId === "3") {
      return 0;
    } else {
      return 2;
    }
  };

  const dateAnswer = registryDetails && Array.isArray(registryDetails.user_answers)
  ? getUserAnswerByIndex(registryDetails.user_answers, getIndexForRegistry(registryTypeId))
  : null;

  const getFilterValue = () => {
    const { purchased, item_category_id, platform_name } = filterOptions;
    if (purchased !== '') {
      return `purchased:${purchased}`;
    } else if (item_category_id !== '') {
      return `category:${item_category_id}`;
    } else if (platform_name !== '') {
      return `store:${platform_name}`;
    } else {
      return '';
    }
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    const [type, filterValue] = value.split(':');
    let updatedOptions = { ...filterOptions };
  
    switch (type) {
      case 'purchased':
        updatedOptions.purchased = filterValue;
        updatedOptions.item_category_id = '';
        updatedOptions.platform_name = '';
        break;
      case 'category':
        updatedOptions.item_category_id = filterValue;
        updatedOptions.purchased = '';
        updatedOptions.platform_name = '';
        break;
      case 'store':
        updatedOptions.platform_name = filterValue;
        updatedOptions.purchased = '';
        updatedOptions.item_category_id = '';
        break;
      default:
        updatedOptions = {
          platform_name: '',
          item_category_id: '',
          purchased: '',
          sort_by: '',
        };
    }
  
    setFilterOptions(updatedOptions);
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    setFilterOptions({ ...filterOptions, sort_by: value });
  };

  const handleEditAnswerDate = () => {
    const currentBirthdate = dayjs(dateAnswer);
    setAnswerDate(currentBirthdate);
    setOpenCalendar(true);
  };

  const handleSaveAnswerDate = async () => {
    let registryQuestionId;
    if (registryTypeId === "1") {
      registryQuestionId = 3;
    } else if (registryTypeId === "2") {
      registryQuestionId = 6;
    } else if (registryTypeId === "3") {
      registryQuestionId = 7;
    } else {
      showToast("Unsupported registry type", "error");
      return;
    }
  
    const updatedAnswerData = {
      registry_question_id: registryQuestionId,
      question_answer: dayjs(answerDate).format('YYYY-MM-DD'),
      remarks: null,
    };
  
    try {
      await api.updateAnswerDate(accessToken, registryId, updatedAnswerData);
      showToast('Date updated successfully', "success");
  
      fetchRegistryDetails();
      setOpenCalendar(false);
    } catch (error) {
      console.error("Error updating date:", error.message);
      showToast('Failed to update date', "error");
    }
  };

  const handleCloseAnswerDate = () => {
    setOpenCalendar(false);
  };

  const handleFavorite = async (itemId, currentFavoriteValue) => {
    try {
      const newFavoriteValue = !currentFavoriteValue;
      await api.toggleFavoriteStatus(accessToken, itemId, newFavoriteValue);
      fetchRegistryDetails();
      fetchRegistryItems();
  
      if (newFavoriteValue) {
        showToast('This item will now show as a favorite to your guests');
      } else {
        showToast('Removed from your favorites');
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
    }
  };

  const handleOpenDeleteModal = (registryItemId) => {
    setRegistryItemIdToDelete(registryItemId);
    if ($("#unavailable-modal").hasClass("show")) {
      $("#unavailable-modal").modal("hide");
    }
    $("#delete-modal").modal("show");
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await api.deleteRegistryItem(accessToken, registryItemIdToDelete);
      showToast('Your gift has been deleted', "success");
      $("#delete-modal").modal("hide");
      fetchRegistryDetails();
      fetchRegistryItems();
    } catch (error) {
      console.error("Error deleting registry item:", error.message);
      showToast('Failed to delete gift', "error");
    } finally {
      setRegistryItemIdToDelete(null);
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    $("#delete-modal").modal("hide");
  };

  const handleAddGiftPage = () => {
    navigate("/add-gifts");
    window.scrollTo(0, 0);
  };

  const handleOpenEditModal = (itemId) => {
    setSelectedItemId(itemId);
    $("#EditGift").modal("show");
  };

  const handleOpenManualGiftAdd = () => {
    $("#manual-gift").modal("show");
  }

  const handleOpenCategoriesModal = () => {
    $("#edit-categories").modal("show");
  };

  const handleOpenPurchaserModal = (itemId) => {
    setPurchasedItemId(itemId);
    setTimeout(() => {
    $("#purchaser-details").modal("show");
    }, 500);
  };

  const handleOpenUnavailableModal = (itemId, itemUrl) => {
    setUnavailableItemId(itemId);
    setUnavailableItemUrl(itemUrl);
    $("#unavailable-modal").modal("show");
  };

  const handleOpenViewModal = (itemId) => {
    setSelectedItemId(itemId);
    $("#ViewGift").modal("show");
  };

  const handleOpenEmailModal = () => {
    $("#activate-account").modal("show");
  };

  const handleShareIndividualGift = (itemId, registryId) => {
    if (!itemId || !registryId) return;
  
    const baseUrl = "https://quickregistry.in/view-product";
    const shareUrl = `${baseUrl}?itemId=${itemId}&regId=${registryId}`;
    const message = `Check out this gift 🎁 on quick registry: ${shareUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar registryDetails={registryDetails}/>
        </header>
        {loading ? (
            <div className="custom-loader-container main-loader fs-5">
              <div className="spinner-border custom-loader" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
          <>
          {registryDetails !== null && !registryDetails?.email_verified && (
            <div className="email-header">
              <div className="row">
                <div className="email-banner">
                  <p>
                    <Link onClick={handleOpenEmailModal}>ACTIVATE YOUR ACCOUNT</Link> TO MANAGE YOUR REGISTRY
                  </p>
                </div>
              </div>
            </div>
          )}
          <main className="main main-dashboard">
            <article>
              <section>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="dashboard">
                        <div className="user-dashboard mb-3">
                          <div className="user-name text-center">
                            <h3>
                              Hi, {registryDetails?.user_first_name}&nbsp;{getUserAnswerByIndex(registryDetails?.user_answers ?? [], 1) &&
                              `& ${getUserAnswerByIndex(registryDetails?.user_answers ?? [], 1).split(' ')[0]}`}!
                            </h3>
                          </div>
                          <div className="user-stats">
                            <ul>
                              <li>
                                <Link to="#">
                                  <h6>Total Gifts</h6>
                                  <h4>{registryDetails?.registry_total_qty}</h4>
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <h6>Gifts purchased</h6>
                                  <h4>{registryDetails?.registry_purchased_qty}</h4>
                                </Link>
                              </li>
                              <li className="custom-disabled">
                                <Link to="#">
                                  <h6>Cash Fund</h6>
                                  <h4>0 INR</h4>
                                </Link>
                              </li>
                              <li className="custom-disabled">
                                <Link to="#">
                                  <h6>Synced registries</h6>
                                  <h4>0</h4>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="baby-stats">
                            <div className="baby-date user-detials-btn">
                              <p>{getRegistryTypeFormat(registryTypeId, dateAnswer)}</p>
                                <Link to="#">
                                  <i className="la la-pen" onClick={handleEditAnswerDate}></i>
                                </Link>
                            </div>
                              <Dialog open={openCalender} style={{width: isPortrait && "120vw", marginLeft: isPortrait && "-10vw"}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <StaticDatePicker
                                    orientation={isPortrait ? 'portrait' : 'landscape'}
                                    value={answerDate}
                                    onChange={setAnswerDate}
                                    renderInput={(params) => <input {...params} />}
                                    onAccept={handleSaveAnswerDate}
                                    onClose={handleCloseAnswerDate}
                                  />
                                </LocalizationProvider>
                              </Dialog>
                            <div className="login icons-right primary-button">
                              <button type="button" onClick={handleOpenManualGiftAdd}>Add Personal Gift</button>&nbsp;
                              <button type="button" onClick={handleOpenCategoriesModal} className={registryDetails?.registry_total_qty === 0 ? 'custom-disabled' : ''}>Edit Categories</button>
                            </div>
                          </div>
                        </div>
                        <div className="user-dashboard">
                          <div className="top-filter">
                            <div className="filter-box">
                              <div className="filter-part">
                                <label htmlFor="">Filter:</label>
                                <div className={`select-wrapper ${registryDetails?.registry_total_qty === 0 ? 'custom-disabled' : ''}`}>
                                  <select
                                    name="filter"
                                    id="filterSelect"
                                    className="form-control category-width"
                                    value={getFilterValue()}
                                    onChange={handleFilterChange}
                                  >
                                    <option value="">All Gifts</option>
                                    <option value="purchased:1">Purchased</option>
                                    <option value="purchased:0">Unpurchased</option>
                                      {registryDetails && (
                                        <>
                                          <optgroup label="BY CATEGORY">
                                            {registryDetails.item_categories
                                              .filter(category => category.name !== "All Gifts")
                                              .sort((a, b) => a.name.localeCompare(b.name))
                                              .map(category => (
                                                <option key={category.id} value={`category:${category.id}`}>
                                                  {category.name}
                                                </option>
                                              ))
                                            }
                                          </optgroup>
                                          <optgroup label="BY STORE">
                                            {registryDetails.platform_names
                                              .sort((a, b) => a.platform_name.localeCompare(b.platform_name))
                                              .map(store => (
                                                <option key={store.id} value={`store:${store.platform_name}`}>
                                                  {store.platform_name}
                                                </option>
                                              ))
                                            }
                                          </optgroup>
                                        </>
                                      )}
                                  </select>
                                </div>
                              </div>
                                <div className="filter-part">
                                  <label htmlFor="">Sort By:</label>
                                  <div className={`select-wrapper ${registryDetails?.registry_total_qty === 0 ? 'custom-disabled' : ''}`}>
                                    <select
                                      name="sorting"
                                      id="sortingselect"
                                      className="form-control auto-width"
                                      value={filterOptions.sort_by}
                                      onChange={handleSortChange}
                                    >
                                      <option value="">Recently Added</option>
                                      <option value="price_high_to_low">Price: high to low</option>
                                      <option value="price_low_to_high">Price: low to high</option>
                                      <option value="favorite">Most desired</option>
                                      <option value="name_asc">A to Z</option>
                                      <option value="name_desc">Z to A</option>
                                      {/* <option value="Category">Category</option>
                                      <option value="PurchasedFirst">Purchased First</option>
                                      <option value="UnpurchasedFirst">Unpurchased First</option>
                                      <option value="ByStores">By Stores</option> */}
                                    </select>
                                </div>
                              </div>
                            </div>
                            <div className="how-add-gifts">
                            <div className={`share-registry float-start ${registryDetails?.email_verified ? '' : 'custom-disabled'}`}>
                              <Link to="#" data-bs-toggle="modal" data-bs-target="#shareRegistry">
                                <img src="/assets/images/icon/share-icon.png" alt="Share Icon" />
                              </Link>
                            </div>
                            {!fromMobileApp && (
                              <div className="add-gifts primary-button float-end">
                              <button onClick={handleAddGiftPage} type="button">How to add gifts</button>
                            </div>
                            )}
                            </div>
                          </div>
                          <div className="gift-list my-4">
                            <div className="view-buttons">
                              <h4>Gift List</h4>
                              <div className="grid-buttons">
                                <button
                                  className={`grid square-btn${isGridMode ? " active" : ""}`}
                                  onClick={() => handleToggleViewMode(true)}
                                >
                                  <i className="la la-square-full"></i>
                                </button>
                                <button
                                  className={`list-btn square-btn${!isGridMode ? " active" : ""}`}
                                  onClick={() => handleToggleViewMode(false)}
                                >
                                  <i className="la la-bars"></i>
                                </button>
                              </div>
                            </div>
                            <div className="full-list">
                              {registryItems && registryItems.length > 0 ? (
                                  <ul className="grid row mt-4">
                                    {registryItems.map((item) => (
                                      <li key={item.id} className="col-xl-4 col-md-6">
                                      <div className="product-details">
                                          <Link to="#" onClick={() => handleOpenViewModal(item.id)}>
                                          <div className={`product-img ${item.purchased_qty >= item.required_qty ? ' custom-disabled' : ''}`}>
                                              {item.image ? (
                                              <img src={item.image} alt="" className="img-fluid d-block mx-auto" style={{ height: '250px', objectFit: 'contain' }} />
                                              ) : (
                                              <img src="/assets/images/no_image_available.png" style={{ height: '250px', objectFit: 'contain' }} alt="" />
                                              )}
                                          </div>
                                          <div className={`product-desc ${item.purchased_qty >= item.required_qty ? ' custom-disabled' : ''}`}>
                                            <p className="clamp-lines">{item.item_name}</p>
                                          </div>
                                          <div className={`product-quantity ${item.purchased_qty >= item.required_qty ? ' custom-disabled' : ''}`}>
                                            <ul>
                                                <li>Desired: {item.required_qty}</li>
                                                <li>Purchased: {item.purchased_qty}</li>
                                            </ul>
                                            <ul>
                                                {item.item_details.color && (
                                                  <li>Colour: {item.item_details.color}</li>
                                                )}
                                                {item.item_details.size && (
                                                  <li>Size: {item.item_details.size}</li>
                                                )}
                                                {!item.item_details.color && !item.item_details.size && <span>&nbsp;</span>}
                                              </ul>
                                          </div>
                                          <div className="product-details-container">
                                            <div className={`product-price ${item.purchased_qty >= item.required_qty ? 'custom-disabled' : ''}`}>
                                              <h3>₹ {item.price}</h3>
                                            </div>
                                            {item.status === "Unavailable" ? (
                                              <div className="purchaser-box unavailable-box" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                                                <h6 className="purchased-status">UNAVAILABLE</h6>
                                                <Link to="#" className="view-purchaser-info" onClick={() => handleOpenUnavailableModal(item.id, item.item_url)}>VIEW INFO</Link>
                                              </div>
                                            ) : (
                                              item.purchased_qty > 0 && (
                                                <div className="purchaser-box" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                                                  <h6 className="purchased-status">PURCHASED</h6>
                                                  <Link to="#" className="view-purchaser-info" onClick={() => handleOpenPurchaserModal(item.id)}>VIEW INFO</Link>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          </Link>
                                          <div className="product-hover">
                                            <Link to="#" onClick={() => handleFavorite(item.id, item.favorite)}>
                                              {item.favorite ? <i className="la la-heart"></i> : <i className="la la-heart-o"></i>}
                                            </Link>
                                            <Link to="#" onClick={() => handleOpenEditModal(item.id)}><i className="la la-pen"></i></Link>
                                            <Link to="#" className={item.status === 'Unavailable' ? 'custom-disabled' : ''}
                                              onClick={(e) => item.status === "Unavailable" ? e.preventDefault() : handleShareIndividualGift(item.id, registryDetails?.registry_id)}>
                                              <i className="la la-share-square"></i>
                                            </Link>
                                            <Link to="#" onClick={() => handleOpenDeleteModal(item.id)}><i className="la la-trash"></i></Link>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                <div className="registry-message">
                                  {(filterOptions.purchased === "1") && (
                                    <div className="text-center">
                                      <p className="no-gift-message">No gifts have been purchased yet</p>
                                      <p className="no-gift-info">Have you shared your registry?</p>
                                      <p className="no-gift-info">
                                        <Link to="#" data-bs-toggle="modal" data-bs-target="#shareRegistry" className="no-gift-info change-name-color">
                                          Click here </Link>to share
                                      </p>
                                    </div>
                                  )}
                                  {(filterOptions.purchased === "0") && <p className="no-gift-other-info">No unpurchased gifts found</p>}
                                  {(filterOptions.item_category_id || filterOptions.platform_name) && <p className="no-gift-other-info text-center">You don’t have any gifts in this category</p>}
                                  {!filterOptions.purchased && !filterOptions.item_category_id && !filterOptions.platform_name && (
                                    <div className="text-center">
                                      <p className="no-gift-message">Once you add your first gift, you'll see it here</p>
                                      <p className="no-gift-info">Need some inspiration?</p>
                                      <p className="no-gift-info">
                                        Check out our <Link to="/shop" className="no-gift-info change-name-color">Popular Gifts</Link>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="modal fade" id="delete-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body row">
                              <div className="confirmation-modal text-center mt-4">
                                <h5 className="mb-4">Delete a Gift</h5>
                                <p> Are you sure you want to delete this gift?</p>
                              </div>
                              <div className="user-detials-btn adjust-lower-btn text-center mb-3">
                                <button type="button" className="common-modal-btn common-btn mb-2" onClick={handleCancelDelete}>NO</button>
                                <button type="button" className="btn common-modal-btn common-btn active-btn" onClick={handleConfirmDelete} disabled={isDeleting}>YES</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </main>
          </>
          )}
        <EditGiftModal
          registryItems={registryItems}
          registryDetails={registryDetails}
          itemId={selectedItemId}
          fetchRegistryDetails={fetchRegistryDetails}
          fetchRegistryItems={fetchRegistryItems}
        />
        <PersonalGiftModal 
          registryDetails={registryDetails}
          fetchRegistryDetails={fetchRegistryDetails}
          fetchRegistryItems={fetchRegistryItems}
        />
        <EditCategoriesModal
          registryDetails={registryDetails}
          fetchRegistryDetails={fetchRegistryDetails}
        />
        <ActivateAccountModal />
        <ViewGiftModal 
          itemId={selectedItemId}
          registryItems={registryItems}
        />
        <PurchaserDetailsModal
          itemId={purchasedItemId}
          fetchRegistryItems={fetchRegistryItems}
          fetchRegistryDetails={fetchRegistryDetails}
        />
        <UnavailableModal
          itemId={unavailableItemId}
          itemUrl={unavailableItemUrl}
          fetchRegistryItems={fetchRegistryItems}
          fetchRegistryDetails={fetchRegistryDetails}
        />
        <ShareRegistryModal
          fetchRegistryDetails={fetchRegistryDetails}
          registryPublicKey={registryDetails?.registry_public_key} 
        />
        <Footer />
      </div>
    </div>
  );
};

export default DashboardPage;