export const cleanupModalArtifacts = () => {
  const backdrops = document.querySelectorAll(".modal-backdrop");
  backdrops.forEach((backdrop) => backdrop.remove());

  document.body.classList.remove("modal-open");
  document.body.style.overflow = "";

  const modals = document.querySelectorAll(".modal.show");
  modals.forEach((modal) => {
    modal.classList.remove("show");
    modal.style.display = "none";
  });
};