/* global $ */
import React, { useState, useEffect } from "react";
import { showToast } from "../../../utils/toastUtils";
import { getRegistryCredentials } from "../../../utils/registryUtils";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import api from "../../../services/api";

const PersonalGiftModal = ({registryDetails, fetchRegistryDetails, fetchRegistryItems}) => {
  const [loading, setLoading] = useState(false);
  const [registryName, setRegistryName] = useState("");
  const [price, setPrice] = useState("");
  const [notes, setNotes] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [giftUrl, setGiftUrl] = useState("");
  const [giftTitle, setGiftTitle] = useState("");
  const [productImage, setProductImage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [isGiftUrlValid, setIsGiftUrlValid] = useState(true);
  const [isGiftTitleValid, setIsGiftTitleValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [isQtyValid, setIsQtyValid] = useState(true);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const { accessToken, registryId, fromiOSDevice } = getRegistryCredentials();

  useEffect(() => {
    if (registryDetails?.registries_list && Array.isArray(registryDetails.registries_list)) {
      const registry = registryDetails.registries_list.find(r => r.id === Number(registryId));
      setRegistryName(registry ? registry.name : '');
    }
  }, [registryDetails, registryId]);

  const clearGiftDetails = () => {
    setPrice("");
    setSize("");
    setColor("");
    setNotes("");
    setQuantity(1);
    setGiftUrl("");
    setGiftTitle("");
    setProductImage("");
    setUploadedFileName("");
    setSelectedCategory(1);
    setIsGiftUrlValid(true);
    setIsGiftTitleValid(true);
    setIsPriceValid(true);
    setIsQtyValid(true);
  };

  const handleAddGift = async () => {
    const staticImageUrl = "https://quickregistry.in/assets/images/no_image_available.png";
    if (!giftUrl || !giftTitle || !price || !quantity) {
      showToast("Please enter required gift details");
      if (!giftUrl) {
        setIsGiftUrlValid(false);
      }
      if (!giftTitle) {
        setIsGiftTitleValid(false);
      }
      if (!price) {
        setIsPriceValid(false);
      }
      if (!quantity) {
        setIsQtyValid(false);
      }
      return;
    }

    if (quantity < 1 || quantity > 99) {
      setIsQtyValid(false);
      showToast("Please enter valid gift quantity");
      return;
    }

    try {
      setLoading(true);
      const itemData = {
        registry_id: registryId,
        item_category_id: selectedCategory,
        item_name: giftTitle,
        item_url: giftUrl,
        image: productImage || staticImageUrl,
        uid: "10031355",
        item_details: {
          size: size,
          color: color,
          note: notes,
        },
        price: price,
        required_qty: quantity,
        purchased_qty: "0",
        locked_qty: "0",
        added_by: {
          By: "User",
        },
      };

      await api.createRegistryItem(accessToken, registryId, itemData);
      showToast("Gift Added Successfully", "success");
      setLoading(false);
      clearGiftDetails();
      fetchRegistryDetails();
      fetchRegistryItems();
      $("#manual-gift").modal("hide");
    } catch (error) {
      setLoading(false);
    }
  };

  const handleiOSUpload = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
        quality: 100
      });
  
      const imageUrl = photo.dataUrl;
      setProductImage(imageUrl);
      setUploadedFileName("Selected Photo");
    } catch (error) {
      console.error('Error selecting photo', error);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image')) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setProductImage(event.target.result);
          setUploadedFileName(file.name);
        };
        reader.readAsDataURL(file);
      } else {
        showToast("Please upload a valid image file (e.g., PNG, JPEG, JPG)");
      }
    }
  };

  const handleClose = () => {
    clearGiftDetails();
  }

  return (
    <div className="modal fade" id="manual-gift" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog add-gifts-popup adjust-popup">
        <div className="modal-content custom-modal-content">
          <div className="modal-header">
            <p>ADD PERSONAL GIFT</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
          </div>
          <div className="modal-body login-box row">
            <div className="col-lg-6 col-sm-6">
              <div className="product-form mt-3">
                <form>
                  <div className="user-box">
                    <input type="text" disabled value={registryName} />
                    <label>Add this to</label>
                  </div>
                  <div className="product-imgs">
                    <div className="img-display mb-3">
                      <div className="img-showcase">
                        {productImage ? (
                          <img
                            src={productImage}
                            alt=""
                            className="img-fluid d-block mx-auto"
                            style={{ height: '220px', objectFit: 'contain' }}
                          />
                        ) : (
                          <img
                            src="/assets/images/icon-no-image.svg"
                            alt=""
                            className="img-fluid d-block mx-auto"
                            style={{ height: '220px', objectFit: 'contain' }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="upload-button text-center">
                    <label
                      htmlFor="file-upload"
                      className="custom-upload-btn custom-edit-btn"
                      onClick={fromiOSDevice ? handleiOSUpload : null}
                    >
                      <i className="la la-camera fs-5"></i> {uploadedFileName ? 'Change Image' : 'Select Image'}
                    </label>
                    {!fromiOSDevice && (
                      <input
                        type="file"
                        id="file-upload"
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                    )}
                  </div>
                  <div className="user-box mt-4">
                    <textarea
                      id="note"
                      name="Note"
                      placeholder="Notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="product-form mt-3">
                <form noValidate>
                  <div className="user-box">
                    <input
                      type="text"
                      name="gift-url"
                      value={giftUrl}
                      onChange={(e) => {
                        setGiftUrl(e.target.value);
                        setIsGiftUrlValid(true);
                      }}
                      className={`form-control ${
                        !isGiftUrlValid ? "is-invalid" : ""
                      }`}
                      required
                    />
                    <label>Gift URL</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      name="Gift Title"
                      value={giftTitle}
                      onChange={(e) => {
                        setGiftTitle(e.target.value);
                        setIsGiftTitleValid(true);
                      }}
                      className={`form-control ${
                        !isGiftTitleValid ? "is-invalid" : ""
                      }`}
                      required
                    />
                    <label>Gift Title</label>
                  </div>
                  <div className="user-box product-popup-price">
                    <div className="product-column">
                      <input
                        type="text"
                        name="Price"
                        value={`₹ ${price}`}
                        onChange={(e) => {
                          const numericValue = e.target.value
                            .replace(/₹/g, "")
                            .trim();
                          setPrice(numericValue);
                          setIsPriceValid(true);
                        }}
                        className={`form-control ${
                          !isPriceValid ? "is-invalid" : ""
                        }`}
                        required
                      />
                      <label>Price</label>
                    </div>
                    <div className="product-column">
                      <div className="select-wrapper">
                        <select
                          name="Currency"
                          id="currencySelect"
                          className="form-control"
                        >
                          <option value="Inr">INR</option>
                        </select>
                      </div>
                    </div>
                    <div className="product-column">
                      <input
                        type="number"
                        name="Qty"
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                          setIsQtyValid(true);
                        }}
                        className={`form-control ${
                          !isQtyValid ? "is-invalid" : ""
                        }`}
                        min={1}
                        max={99}
                        required
                      />
                      <label>Qty</label>
                    </div>
                  </div>
                  <div className="user-box product-field">
                    <div className="product-column">
                      <input
                        type="text"
                        name="Size"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        required
                      />
                      <label>Size</label>
                    </div>
                    <div className="product-column">
                      <input
                        type="text"
                        name="Color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        required
                      />
                      <label>Color</label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="mb-2"> Category </label>
                    <div className="select-wrapper">
                      <select
                        name="Category"
                        id="categorySelect"
                        className="form-control"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        {registryDetails &&
                          registryDetails.item_categories &&
                          registryDetails.item_categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </form>
                <div className="adjust-personal-btn">
                  <button type="button" className="btn btn-primary form-btn main-btn" onClick={handleAddGift} disabled={loading}>
                    {loading ?  "ADDING GIFT..." :  "ADD GIFT"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalGiftModal;
