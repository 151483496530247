import axios from "axios";
import { getRegistryCredentials, clearRegistryCredentials } from "../utils/registryUtils";

const { fromMobileApp } = getRegistryCredentials();

const apiInstance = axios.create({
  baseURL: "https://api.quickregistry.in",
  // baseURL: "https://7a2e-49-36-232-234.ngrok-free.app",
  // headers: {
  //   'ngrok-skip-browser-warning': '69420',
  // }
});

const initializeAxiosInterceptors = (navigate) => {
  apiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.message === "Unauthenticated.") {
        clearRegistryCredentials();
        if (fromMobileApp) {
          navigate('/m-homepage');
        } else {
          navigate('/');
        }
        return Promise.reject(error);
      }

      const handledError = handleError(error);
      return Promise.reject(handledError);
    }
  );
};

export const initializeApi = (navigate) => {
  initializeAxiosInterceptors(navigate);
};

const handleError = (error) => {
  if (error?.response) {
    const errorMessage = error.response.data.message || error.response.data.error || "An unexpected error occurred.";
    return { success: false, message: errorMessage };
  } else if (error?.request) {
    return { success: false, message: "No response received from the server." };
  } else {
    return { success: false, message: "An error occurred during the API request." };
  }
};

const getShopProducts = async (source, subcategory = "", searchQuery = "", sortBy = "", maxPrice, minPrice, page) => {
  try {
    const params = {
      ...(subcategory && { subcategory }),
      ...(searchQuery && { q: searchQuery }),
      ...(sortBy && { sortBy }),
      ...(maxPrice !== undefined && { maxPrice }),
      ...(minPrice !== undefined && { minPrice }),
      page
    };

    const response = await apiInstance.get(`/api/shop/${source}`, {
      params
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching shop products:', error);
    handleError(error);
    return null;
  }
};

const getShopProductDetails = async (source, identifier, platformId) => {
  try {
    let url = `/api/shop/${source}/${identifier}`;
    if (source === 'all' && platformId) {
      url += `?platform_id=${platformId}`;
    }
    const response = await apiInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching shop product images:', error);
    handleError(error);
  }
};

const getShopSubcategory = async (categories) => {
  try {
    const categoryParam = Array.isArray(categories) ? categories.join(',') : categories;
    const response = await apiInstance.get(`/api/filter/${categoryParam}`);

    return response.data;
  } catch (error) {
    console.error('Error fetching subcategories:', error);
    return handleError(error);
  }
};

const getVisitorData = async (publicKey, visitorPassword, filterOptions) => {
  try {
    const queryParams = new URLSearchParams(filterOptions).toString();
    const urlWithParams = `/api/visitor-view/${publicKey}?${queryParams}`;
    const response = await apiInstance.post(urlWithParams, {
      ...(visitorPassword && { visitor_password: visitorPassword }),
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching visitor data:', error);
    throw error;
  }
};

const getVisitorItem = async (itemId, regId, visitorPassword = '') => {
  try {
    const url = `/api/visitItem/itemId=${itemId}&regId=${regId}` + (visitorPassword ? `?visitor_password=${visitorPassword}` : '');
    const response = await apiInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching visitor item:', error);
    throw error;
  }
};

const getRegistryItems = async (accessToken, registryId, filterOptions) => {
  try {
    const queryParams = new URLSearchParams(filterOptions).toString();
    const response = await apiInstance.get(`/api/items/${registryId}/filter?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getRegistryList = async (accessToken, userId) => {
    try {
      const response = await apiInstance.get(`/api/registry/${userId}/list`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      handleError(error);
    }
  };

const getDashboardDetails = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.get(`/api/dashboard/${registryId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAllCategories = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.get(`/api/categories/${registryId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getPurchaserDetails = async (accessToken, itemId) => {
  try {
    const response = await apiInstance.get(`/api/buyer/${itemId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching buyer information:', error);
    throw error;
  }
};

const getRequestPasswordInfo = async (requestId) => {
  try {
    const response = await apiInstance.get(`/api/passwordRequestId=${requestId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getChecklistDetails = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.get(`/api/showChecklist/${registryId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSettingDetails = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.get(`/api/showDetails/${registryId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getGuestbookDetails = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.get(`/api/diaryEntries/${registryId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getVisitorDetails = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.get(`/api/visitorDetails/${registryId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getAddressToVisitor = async (accessToken, publicKey) => {
  try {
    const response = await apiInstance.post('/api/showAddressToVisitor', {
      registry_public_key: publicKey
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

    return response.data;
  } catch (error) {
    console.error('Error fetching address to visitor:', error);
    handleError(error);
  }
};

const searchRegistries = async (searchTerm) => {
  try {
    const response = await apiInstance.post(`/api/registries/search`, {
      query: searchTerm
    });

    return response.data;
  } catch (error) {
    console.error('Error searching registries:', error);
    throw error;
  }
};

const sendVerificationEmail = async (email) => {
  try {
    const response = await apiInstance.post(`/api/send-verification-email`, {
      email: email
    });

    return response.data;
  } catch (error) {
    console.error('Error sending verification email:', error);
    throw error;
  }
};

const createRegistryId = async (accessToken, userId, registryTypeId, name) => {
  try {
    const response = await apiInstance.post(`/api/registry/createRegistryId`, 
      {
        user_id: userId,
        registry_type_id: registryTypeId,
        name: name
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const completeRegistryDetails = async (accessToken, registryId, registryDetails) => {
  try {
    const response = await apiInstance.put(`/api/registry/${registryId}/fillRegistryData`, registryDetails, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });    

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createRegistryItem = async (accessToken, registryId, itemData) => {
  try {
    const response = await apiInstance.post(`/api/item/create`, 
    {
      registry_id: registryId,
      ...itemData,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createCategory = async (accessToken, registryId, categoryName) => {
  try {
    const response = await apiInstance.post(`/api/category/create`, 
      {
        registry_id: registryId,
        name: categoryName
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating category:", error);
    handleError(error);
  }
};

const createLifafa = async (accessToken, registryId, upiId, qrCode) => {
  try {
    const response = await apiInstance.post(`/api/lifafa/create`, 
      {
        registry_id: registryId,
        upi_id: upiId,
        qr_code: qrCode
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating lifafa:", error);
    handleError(error);
  }
};

const updateRegistryItems = async (accessToken, registryItemId, updatedData) => {
  try {
    const response = await apiInstance.put(`/api/item/${registryItemId}/update`, updatedData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateAnswerDate = async (accessToken, registryId, updatedData) => {
  try {
    const response = await apiInstance.put(`/api/answer/${registryId}`, updatedData, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateCategory = async (accessToken, registryId, categoryId, newCategoryName) => {
  try {
    const response = await apiInstance.put(`/api/category/${categoryId}/update`, 
      {
        registry_id: registryId,
        name: newCategoryName
      }, 
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating category:", error);
    throw error;
  }
};

const updatePasswordRequest = async (requestId, status) => {
  try {
    const response = await apiInstance.post('/api/updatePasswordRequest',
      {
        request_id: requestId,
        status: status,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateChecklist = async (accessToken, registryId, formattedData) => {
  try {
    const response = await apiInstance.post(`/api/updateChecklist/${registryId}`, formattedData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updatePublicUrl = async (accessToken, registryId, publicKey) => {
  try {
    const response = await apiInstance.put(`/api/registry/${registryId}/changeURL`, 
      {
        public_key: publicKey
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating publicKey:", error);
    handleError(error);
  }
};

const updateProfile = async (accessToken, userId, profileData) => {
  try {
    const response = await apiInstance.put(`/api/updateProfile/${userId}`, profileData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateRegistryDetails = async (accessToken, registryId, registryUpdates) => {
  try {
    const response = await apiInstance.put(`/api/registry/${registryId}/update`, 
      registryUpdates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateShipping = async (accessToken, registryId, shippingData) => {
  try { 
    const response = await apiInstance.put(`/api/updateShipping`, 
      {
        registry_id: registryId,
        address: shippingData,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateLifafa = async (accessToken, registryId, lifafaId, upiId, qrCode) => {
  try {
    const payload = {
      registry_id: registryId,
      upi_id: upiId,
    };

    if (qrCode !== null) {
      payload.qr_code = qrCode;
    }
    
    const response = await apiInstance.put(`/api/lifafa/${lifafaId}/update`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating lifafa:", error);
    throw error;
  }
};

const saveUserDetailsAnswers = async (accessToken, registryId, userDetails) => {
  try {
    const response = await apiInstance.post(`/api/answers/save`, {
      registry_id: registryId,
      responses: userDetails
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const makeRegistryPublic = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.put(`/api/registry/${registryId}/makePublic`, {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const toggleFavoriteStatus = async (accessToken, itemId, newFavoriteValue) => {
  try {
    const response = await apiInstance.post(`/api/item/${itemId}/markFavorite`, 
      { 
        set_favorite: newFavoriteValue 
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const makeRegistryPrivate = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.put(`/api/registry/${registryId}/makePrivate`, {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const markItemPurchased = async (accessToken, purchaserName, purchaserEmail, item_id, qty) => {
  try {
    const response = await apiInstance.post(`/api/markPurchased`, {
      name: purchaserName,
      email: purchaserEmail ,
      item_id: item_id,
      qty: qty,
      secret: false,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const markItemAvailable = async (accessToken, itemId) => {
  try {
    const response = await apiInstance.post(`/api/item/markItemAvailable/${itemId}`, {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error marking item as available:', error);
    handleError(error);
  }
};

const markItemUnavailable = async (itemId, regId) => {
  try {
    const response = await apiInstance.post(`/api/visitor/markItemUnavailable/itemId=${itemId}&regId=${regId}`);
    return response.data;
  } catch (error) {
    console.error('Error marking item as unavailable:', error);
    handleError(error);
  }
};

const purchaserDetails = async (purchaserData) => {
  try {
    const response = await apiInstance.post(`/api/visitor/buy-item`, purchaserData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const revealPurchaserDetails = async (accessToken, purchaserId) => {
  try {
    const response = await apiInstance.put(`/api/revealBuyer/${purchaserId}`, {
      secret: false,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const setGuestbookVisibility = async (accessToken, userId, settingName, settingValue) => {
  try {
    const response = await apiInstance.post(`/api/enableDiaryEntry`, {
      user_id: userId,
      setting_name: settingName,
      setting_value: settingValue,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const setVisitorPassword = async (registryId, accessToken, passwordData) => {
  const apiUrlEndpoint = `/api/registry/${registryId}/setPassword`;
  try {
    const response = await apiInstance.put(apiUrlEndpoint, passwordData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error setting visitor password:', error);
    handleError(error);
  }
};

const setRegistryImage = async (accessToken, registryId, profileImage) => {
  try {
    const response = await apiInstance.post(`/api/registry/setRegistryImage`, {
      registry_id: registryId,
      profile_image: profileImage,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error setting registry image:', error);
    handleError(error);
  }
};

const changePassword = async (accessToken, userId, passwordData) => {
  try {
    const response = await apiInstance.put(`/api/changePassword/${userId}`, passwordData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error; 
  }
};

const showAddressToVisitor = async (jsonWebToken, publicKey) => {
  try {
    const response = await apiInstance.post('/api/visitor/showAddress', {
      registry_public_key: publicKey
    }, {
      headers: {
          Authorization: `Bearer ${jsonWebToken}`,
        },
    });

    return response.data;
  } catch (error) {
    console.error('Error showing address to visitor:', error);
    handleError(error);
  }
};

const sendOtpToVisitor = async (publicKey, visitorName, visitorPhoneNumber) => {
  try {
    const response = await apiInstance.post('/api/visitor/sendOTP', {
      registry_public_key: publicKey,
      visitor_name: visitorName,
      visitor_mobile: visitorPhoneNumber
    });

    return response.data;
  } catch (error) {
    console.error('Error sending otp to visitor:', error);
    handleError(error);
  }
};

const validateOtpToVisitor = async (otp, publicKey, visitorPhoneNumber) => {
  try {
    const response = await apiInstance.post(`/api/visitor/validateOTP`, {
      otp: otp,
      registry_public_key: publicKey,
      visitor_mobile: visitorPhoneNumber
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const shippingVisibility = async (accessToken, userId, settingName, settingValue) => {
  try {
    const response = await apiInstance.post(`/api/enableShippingInfo`, {
      user_id: userId,
      setting_name: settingName,
      setting_value: settingValue,
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const forgotPassword = async (email) => {
  try {
    const response = await apiInstance.post(`/api/forgot-password`, {
      email: email
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const validateOTP = async (otp, email) => {
  try {
    const response = await apiInstance.post(`/api/validate-otp`, {
      otp: otp,
      email: email
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const setNewPassword = async (otp, newPassword, confirmPassword) => {
  try {
    const response = await apiInstance.post(`/api/new-password`, {
      otp: otp,
      new_password: newPassword,
      confirm_password: confirmPassword,
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const guestDiaryEntry = async (visitorName, visitorComments, visitorEmail, registryId) => {
  try {
    const response = await apiInstance.post(`/api/visitor/diary-entry`, {
      visitor_name: visitorName,
      visitor_comments: visitorComments,
      visitor_email: visitorEmail,
      registry_id: registryId
    });

    return response.data;
  } catch (error) {
    console.error('Error posting diary entry:', error);
    throw error;
  }
};

const submitFeedback = async (feedbackData) => {
  try {
    const response = await apiInstance.post(`/api/submitFeedback`, feedbackData);
    return response.data;
  } catch (error) {
    console.error('Error submitting feedback:', error);
    handleError(error);
  }
};

const advertiseRequest = async (advertiseData) => {
  try {
    const response = await apiInstance.post(`/api/advertiseRequest`, advertiseData);
    return response.data;
  } catch (error) {
    console.error('Error submitting advertise request:', error);
    handleError(error);
  }
};

const requestVisitorPassword = async (name, mobileNo, publicKey) => {
  try {
    const response = await apiInstance.post(`/api/visitor/passwordRequest`, {
      name: name,
      mobile_no: mobileNo,
      public_key: publicKey,
    });

    return response.data;
  } catch (error) {
    console.error('Error requesting visitor password:', error);
    throw error;
  }
};

const sendQuickReminder = async (publicKey, visitorName, visitorPhoneNumber, visitorMessage) => {
  try {
    const response = await apiInstance.post(`/api/visitor/reminderToAddGift`, {
      registry_public_key: publicKey,
      visitor_name: visitorName,
      visitor_mobile: visitorPhoneNumber,
      visitor_message: visitorMessage,
    });

    return response.data;
  } catch (error) {
    console.error('Error sending quick reminder:', error);
    handleError(error);
  }
};

const deleteRegistryItem = async (accessToken, registryItemId) => {
  try {
    const response = await apiInstance.delete(`/api/item/${registryItemId}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteCategory = async (accessToken, categoryId) => {
  try {
    const response = await apiInstance.delete(`/api/category/${categoryId}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deletePurchaserDetails = async (accessToken, purchaserId) => {
  try {
    const response = await apiInstance.delete(`/api/buyer/${purchaserId}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteGuestbookEntry = async (accessToken, guestbookId) => {
  try {
    const response = await apiInstance.delete(`/api/diaryEntries/${guestbookId}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteLifafaDetails= async (accessToken, lifafaId) => {
  try {
    const response = await apiInstance.delete(`/api/lifafa/${lifafaId}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteRegistryPermanently = async (accessToken, registryId) => {
  try {
    const response = await apiInstance.delete(`/api/registry/${registryId}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

const deleteAccountPermanently = async (accessToken) => {
  try {
    const response = await apiInstance.post(`/api/delete-account`, {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

const fetchProductDetails = async (accessToken, productUrl, rawHTML) => {
  try {
    const response = await apiInstance.post("/api/item/fetch-data",
    {
      url: productUrl,
      rawHTML: rawHTML
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

    return response.data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    handleError(error);
  }
};

const fetchIosProductDetails = async (accessToken, productUrl) => {
  try {
    const response = await apiInstance.post("/api/item/fetchiOS-data",
    {
      url: productUrl
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

    return response.data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    handleError(error);
  }
};

const api = {
  login: async (username, password) => {
    try {
      const response = await apiInstance.post(`/api/login`, {
        email: username,
        password: password,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  googleLogin: async (id_token, key) => {
    try {
      const response = await apiInstance.post("/api/google-login", 
      {
        access_token: id_token,
        key: key
      });

      return response.data;
    } catch (error) {
      console.error("Error signing up with Google:", error);
      throw error;
    }
  },

  appleLogin: async (id_token, key = null, givenName = null, familyName = null) => {
    try {
      const payload = {
        access_token: id_token,
        ...(key && { key }),
        ...(givenName && { givenName }),
        ...(familyName && { familyName })
      };
  
      const response = await apiInstance.post("/api/apple-login", payload);
      return response.data;
    } catch (error) {
      console.error("Error during Apple authentication:", error);
      throw error;
    }
  },  

  register: async (userData) => {
    try {
      const response = await apiInstance.post(`/api/register`, userData);
      return response.data;
    } catch (error) {
      throw error; 
    }
  },

  logout: async (accessToken) => {
    try {
      const response = await apiInstance.post(
        `/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  
  getShopProducts: getShopProducts,
  getShopProductDetails: getShopProductDetails,
  getShopSubcategory: getShopSubcategory,
  getVisitorData: getVisitorData,
  getVisitorItem: getVisitorItem,
  getRegistryItems: getRegistryItems,
  getRegistryList: getRegistryList,
  getDashboardDetails: getDashboardDetails,
  getAllCategories: getAllCategories,
  getPurchaserDetails: getPurchaserDetails,
  getRequestPasswordInfo: getRequestPasswordInfo,
  getChecklistDetails: getChecklistDetails,
  getSettingDetails: getSettingDetails,
  getGuestbookDetails: getGuestbookDetails,
  getVisitorDetails: getVisitorDetails,
  getAddressToVisitor: getAddressToVisitor,
  searchRegistries: searchRegistries,
  sendVerificationEmail: sendVerificationEmail,
  createRegistryId: createRegistryId,
  completeRegistryDetails: completeRegistryDetails,
  createRegistryItem: createRegistryItem,
  createCategory: createCategory,
  createLifafa: createLifafa,
  updateRegistryItems: updateRegistryItems,
  updateAnswerDate: updateAnswerDate,
  updateCategory: updateCategory,
  updatePasswordRequest:updatePasswordRequest,
  updateChecklist: updateChecklist,
  updatePublicUrl: updatePublicUrl,
  updateProfile: updateProfile,
  updateRegistryDetails: updateRegistryDetails,
  updateShipping: updateShipping,
  updateLifafa: updateLifafa,
  saveUserDetailsAnswers: saveUserDetailsAnswers,
  makeRegistryPublic: makeRegistryPublic,
  makeRegistryPrivate: makeRegistryPrivate,
  markItemPurchased: markItemPurchased,
  markItemAvailable: markItemAvailable,
  markItemUnavailable: markItemUnavailable,
  toggleFavoriteStatus: toggleFavoriteStatus,
  purchaserDetails: purchaserDetails,
  revealPurchaserDetails: revealPurchaserDetails,
  setGuestbookVisibility: setGuestbookVisibility,
  setVisitorPassword: setVisitorPassword,
  setRegistryImage: setRegistryImage,
  changePassword: changePassword,
  showAddressToVisitor: showAddressToVisitor,
  sendOtpToVisitor: sendOtpToVisitor,
  validateOtpToVisitor: validateOtpToVisitor,
  shippingVisibility: shippingVisibility,
  forgotPassword: forgotPassword,
  validateOTP: validateOTP,
  setNewPassword: setNewPassword,
  guestDiaryEntry: guestDiaryEntry,
  submitFeedback: submitFeedback,
  advertiseRequest: advertiseRequest,
  requestVisitorPassword: requestVisitorPassword,
  sendQuickReminder: sendQuickReminder,
  deleteRegistryItem: deleteRegistryItem,
  deleteCategory: deleteCategory,
  deletePurchaserDetails: deletePurchaserDetails,
  deleteGuestbookEntry: deleteGuestbookEntry,
  deleteLifafaDetails: deleteLifafaDetails,
  deleteRegistryPermanently: deleteRegistryPermanently,
  deleteAccountPermanently: deleteAccountPermanently,
  fetchProductDetails: fetchProductDetails,
  fetchIosProductDetails: fetchIosProductDetails
};

export default api;