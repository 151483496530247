/* global $ */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import api from "../../services/api";
import GuestbookModal from "../dashboard/modal/GuestbookModal";
import { getRegistryCredentials, clearRegistryCredentials } from '../../utils/registryUtils';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

const Navbar = ({ registryDetails, registryData, visitorData }) => {
  const location = useLocation();
  const { accessToken, registryTypeId, fromMobileApp, fromiOSDevice } = getRegistryCredentials();
  const [activeLink, setActiveLink] = useState("");
  const navbar = document.querySelector('.navbar');
  const header = document.querySelector('header');

  if (header && navbar) {
    const headerHeight = header.offsetHeight;

    window.addEventListener('scroll', function () {
        if (window.scrollY > headerHeight) {
            navbar.classList.add('fixed');
            if (fromiOSDevice) {
                const toggleBtn = document.querySelector(".navbar-toggler");
                if (toggleBtn) {
                    toggleBtn.classList.add("fixed-ios-toggler");
                }
            }
        } else {
            navbar.classList.remove('fixed');
            const toggleBtn = document.querySelector(".navbar-toggler");
            if (toggleBtn) {
                toggleBtn.classList.remove("fixed-ios-toggler");
            }
        }
    });
  }

  useEffect(() => {
    const handleToggleClick = () => {
      const navbarNav = document.querySelector(".navbar-nav");
      if (navbarNav) {
        navbarNav.classList.toggle("active");
      }
    };

    const handleNavCloseClick = () => {
      const navbarNav = document.querySelector(".navbar-nav");
      if (navbarNav) {
        navbarNav.classList.remove("active");
      }
    };

    const handleClickOutside = (event) => {
      const navbarNav = document.querySelector(".navbar-nav");
      const toggleBtn = document.querySelector(".navbar-toggler");
      if (navbarNav && toggleBtn && !navbarNav.contains(event.target) && !toggleBtn.contains(event.target)) {
        navbarNav.classList.remove("active");
      }
    };

    const handleNavLinkClick = (event) => {
      const navbarNav = document.querySelector(".navbar-nav");
      const target = event.target;

      if (target.closest(".close-menu")) {
        navbarNav.classList.remove("active");
      }
    };

    const toggleBtn = document.querySelector(".navbar-toggler");
    const navCloseBtn = document.querySelector(".btn-nav-close");
    const navLinks = document.querySelectorAll(".nav-link");

    if (toggleBtn) {
      toggleBtn.addEventListener("click", handleToggleClick);
    }

    if (navCloseBtn) {
      navCloseBtn.addEventListener("click", handleNavCloseClick);
    }

    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavLinkClick);
    });

    document.addEventListener("click", handleClickOutside);

    const navItems = document.querySelectorAll(".nav-item");

    navItems.forEach((item) => {
      const hasDropdowns = item.querySelector(".dropdown") !== null;
      if (hasDropdowns) {
        item.classList.add("icon");
      }
    });

    return () => {
      if (toggleBtn) {
        toggleBtn.removeEventListener("click", handleToggleClick);
      }
      if (navCloseBtn) {
        navCloseBtn.removeEventListener("click", handleNavCloseClick);
      }
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavLinkClick);
      });
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.startsWith("/categories")) {
      setActiveLink("/shop");
    } else {
      setActiveLink(location.pathname);
    }
  }, [location]);

  const handleLogout = async () => {
    try {
      await api.logout(accessToken);
      GoogleAuth.signOut();
      clearRegistryCredentials();
      window.location.replace("/");
    } catch (error) {
      clearRegistryCredentials();
      window.location.replace("/");
      console.error("Logout error:", error.message);
    }
  };

  const handleVisitorPage = () => {
    $('#viewAsVisitorModal').modal('show');
  };

  const handleYes = () => {
    const url = `/giftlist/${registryDetails.registry_public_key}`;
    const target = fromMobileApp ? '_self' : '_blank';
    
    window.open(url, target);
    $('#viewAsVisitorModal').modal('hide');
  };

  const handleNo = () => {
    $('#viewAsVisitorModal').modal('hide');
  };

  const handleOpenGuestBookModal = () => {
    $("#guest-book").modal("show");
  };

  const scrollToGuides = () => {
    const guideSection = document.getElementById("baby-guides");
    if (guideSection) {
      const yOffset = guideSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  const isMainPage = ["/"].includes(location.pathname);
  const isViewAsVisitor = location.pathname.includes("/dashboard");
  const isForgotPage = location.pathname.includes("/forgot-password");
  const isVisitorPage = location.pathname.includes("/giftlist");
  const isProductOrPurchasePage = ["/view-product", "/purchase-process"].includes(location.pathname);
  const isBabyOrInfoPage = ["/baby-checklist", "/add-gifts", "/script-blocked"].includes(location.pathname);
  const isStaticOrRegistryPage = ["/search", "/aboutus", "/shop", "/privacy-policy", "/terms&conditions", "/advertise-with-us", "/feedback"].includes(location.pathname) || location.pathname.startsWith("/categories");
  const isDashboardPage = ["/dashboard", "/popular-gifts", "/baby-checklist", "/registry-settings", "/guestbook", "/visitor-setup","/notifications", "/add-gifts", "/script-blocked"].includes(location.pathname);

  return (
    <div className="bottom-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className={`navbar navbar-expand-lg navbar-light align-content-center ${fromiOSDevice ? 'ios-nav' : ''}`}>
              <div className="div-mobile mobile-logo">
                <Link className="navbar-logo" to={fromMobileApp ? "/dashboard" : "/"}>
                  <img src="/assets/images/logo.png" alt="logo" loading="lazy" />
                </Link>
              </div>

              {(isMainPage || isStaticOrRegistryPage || isVisitorPage || isDashboardPage || (accessToken && (isProductOrPurchasePage || isForgotPage))) && (
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              )}

              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-close">
                  <button 
                    className="btn-nav-close" 
                    style={{ visibility: fromiOSDevice ? 'hidden' : 'visible' }}
                  >
                    <span className="close-btn">+</span>
                  </button>
                </li>

                {(isMainPage || (!accessToken && (isStaticOrRegistryPage || isBabyOrInfoPage || (isVisitorPage && !registryData)))) && (
                  <>
                    {accessToken && (
                      <li className="nav-item find-registry">
                        <Link to="/search" className="nav-link">
                          <i className="la la-search"></i> Find A Registry?
                        </Link>
                      </li>
                    )}
                    <li className="nav-item">
                    <Link className={`nav-link ${activeLink === "/" || activeLink === "/baby-checklist" ? "active" : ""}`}>
                      Baby
                    </Link>
                      <ul className="dropdown">
                        {!fromMobileApp && (
                          <li className="nav-item">
                            <Link to="/" className={`nav-link ${activeLink === "/" ? "active" : ""}`}>
                              What is QR
                            </Link>
                          </li>
                        )}
                        <li className="nav-item">
                          <Link to={accessToken ? "/onboarding-process" : "/signup"}  className="nav-link">
                            Create a baby QR
                          </Link>
                        </li>
                        {!fromMobileApp && (
                          <li className="nav-item">
                            <Link to="#" className={`nav-link ${activeLink === "" ? "active" : ""}`} onClick={scrollToGuides}>
                              Baby Guides
                            </Link>
                          </li>
                        )}
                        <li className="nav-item">
                          <Link to="/baby-checklist" className={`nav-link ${activeLink === "/baby-checklist" ? "active" : ""}`}>
                            Baby Checklist
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                        Other Registries
                      </Link>
                      <ul className="dropdown">
                        <li className="nav-item">
                          <Link to={accessToken ? "/onboarding-process" : "/signup"} className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                            Housewarming
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={accessToken ? "/onboarding-process" : "/signup"} className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                            Wedding
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={accessToken ? "/onboarding-process" : "/signup"} className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                            Birthday 
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={accessToken ? "/onboarding-process" : "/signup"} className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                            Any Occasion
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link to="/shop" className={`nav-link ${activeLink === "/shop" ? "active" : ""}`}>
                        Shop
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/aboutus" className={`nav-link ${activeLink === "/aboutus" ? "active" : ""}`}>
                        About US
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${activeLink === "/advertise-with-us" ? "active" : ""}`}>
                        QR Business
                      </Link>
                      <ul className="dropdown">
                        <li className="nav-item">
                          <Link to="/advertise-with-us" className={`nav-link ${activeLink === "/advertise-with-us" ? "active" : ""}`}>
                            Partner with US
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </>
                )}

                {((isDashboardPage || isStaticOrRegistryPage) && accessToken) && (
                  <>
                    <li className="nav-item find-registry">
                      <Link to="/search" className="nav-link">
                        <i className="la la-search"></i> Find A Registry?
                      </Link>
                    </li>
                    <li className="nav-item nav-btn">
                      <Link to="/dashboard" className={`nav-link ${activeLink === "/dashboard" ? "active" : ""}`}>
                        My Gifts
                      </Link>
                    </li>
                    {/* {!fromMobileApp && (
                      <li className="nav-item">
                        <Link to="#" className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                          Things To Do
                        </Link>
                      </li>
                    )} */}
                    <li className="nav-item">
                      <Link to="/shop" className={`nav-link ${activeLink === "/shop" ? "active" : ""}`}>
                        Shop
                      </Link>
                    </li>
                    {registryTypeId === "1" && (
                      <li className="nav-item">
                        <Link className={`nav-link ${activeLink === "/popular-gifts" || activeLink === "/baby-checklist" ? "active" : ""}`}>
                          Things Parents Love
                        </Link>
                        <ul className="dropdown">
                          <li className="nav-item" title="Coming Soon!">
                            <Link to="/popular-gifts" className={`nav-link disabled ${activeLink === "/popular-gifts" ? "active" : ""}`}>
                              Popular Gifts
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/baby-checklist" className={`nav-link ${activeLink === "/baby-checklist" ? "active" : ""}`}>
                              Baby Checklist
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                    {registryTypeId === "2" && (
                      <li className="nav-item">
                        <Link className={`nav-link ${activeLink === "/popular-gifts" || activeLink === "/wedding-checklist" ? "active" : ""}`}>
                          Things Couples Love
                        </Link>
                        <ul className="dropdown">
                          <li className="nav-item" title="Coming Soon!">
                            <Link to="/popular-gifts" className={`nav-link disabled ${activeLink === "/popular-gifts" ? "active" : ""}`}>
                              Popular Gifts
                            </Link>
                          </li>
                          <li className="nav-item" title="Coming Soon!">
                            <Link to="/wedding-checklist" className={`nav-link disabled ${activeLink === "/wedding-checklist" ? "active" : ""}`}>
                              Wedding Checklist
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                    <li className="nav-item">
                      <Link className={`nav-link ${activeLink === "/registry-settings" || activeLink === "/visitor-setup" || activeLink === "/guestbook" ? "active" : ""}`}>
                        Settings
                      </Link>
                      <ul className="dropdown">
                        <li className="nav-item">
                          <Link to="/guestbook" className={`nav-link ${activeLink === "/guestbook" ? "active" : ""}`}>
                            Guestbook
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/visitor-setup" className={`nav-link ${activeLink === "/visitor-setup" ? "active" : ""}`}>
                            Visitor Setup
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/registry-settings" className={`nav-link ${activeLink === "/registry-settings" ? "active" : ""}`}>
                            Registry Settings
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {isViewAsVisitor && registryDetails && (
                      <li className="nav-item">
                        <Link onClick={fromMobileApp ? handleYes : handleVisitorPage} className={`nav-link close-menu ${registryDetails && !registryDetails.email_verified ? 'disabled' : ''}`}>
                          View as a Visitor
                        </Link>
                      </li>
                    )}
                    <li className="nav-item adjust-option">
                      <Link to="/onboarding-process" className="nav-link">
                        <i className="la la-plus-circle"></i> Create New Registry
                      </Link>
                    </li>
                    <li className="nav-item adjust-option">
                      <Link to="/all-registry" className="nav-link">
                        <i className="la la-cog"></i> Manage Registries
                      </Link>
                    </li>

                    <li className="nav-item notification">
                      <Link to="/notifications" className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                        <i className="la la-bell"></i> Notifications
                      </Link>
                    </li>
                    <li className="nav-item logout">
                      <Link className="nav-link" onClick={handleLogout}>
                        <i className="la la-lock"></i> Logout
                      </Link>
                    </li>
                  </>
                )}

                {registryData && isVisitorPage && (
                  <>
                    <li className="nav-item icon-nav">
                      <Link to="#" className={`nav-link ${isVisitorPage ? (location.pathname.includes("/giftlist") ? "active" : "") : (activeLink === "/your-gift-list-path" ? "active" : "")}`}>
                        <i className="la la-gift"></i> Gift List
                      </Link>
                    </li>
                    {visitorData && visitorData.guestbook_visible && isVisitorPage && (
                      <li className="nav-item icon-nav">
                        <Link to="#" onClick={handleOpenGuestBookModal} className="nav-link close-menu">
                          <i className="la la-book"></i> Guest Book
                        </Link>
                      </li>
                    )}
                  </>
                )}

                {(accessToken && (isProductOrPurchasePage || isVisitorPage || isMainPage || isForgotPage)) && (
                  <>
                    <li className="nav-item my-gift">
                      <Link to="/dashboard" className="nav-link">
                        <i className="la la-shopping-bag"></i> My Gifts
                      </Link>
                    </li>
                    <li className="nav-item notification">
                      <Link to="/notifications" className={`nav-link ${activeLink === "" ? "active" : ""}`}>
                        <i className="la la-bell"></i> Notifications
                      </Link>
                    </li>
                    <li className="nav-item logout">
                      <Link className="nav-link" onClick={handleLogout}>
                        <i className="la la-lock"></i> Logout
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="modal fade" id="viewAsVisitorModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-modal-popup">
          <div className="modal-content">
            <div className="modal-body row">
              <div className="col-lg-12">
                <div className="confirmation-visitor-modal text-center">
                  <h5>Are you sure you want to view as visitor?</h5>
                  <p className="visitor-info">You will be able to see which items on your gift list have been purchased.</p>
                </div>
                <div className="user-detials-btn text-center mb-3">
                  <button type="button" className="common-modal-btn common-btn mb-3" onClick={handleNo}>NO, THANKS</button>
                  <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleYes}>YES, PROCEED</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <GuestbookModal />
    </div>
  );
};

export default Navbar;

