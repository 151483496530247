/* global $ */
import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { showToast } from "../../../utils/toastUtils";
import { getRegistryCredentials } from "../../../utils/registryUtils";

const EditDescriptionModal = ({visitorData, fetchVisitorData}) => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [remainingChars, setRemainingChars] = useState(80);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const { accessToken, registryId } = getRegistryCredentials();

  useEffect(() => {
    if (visitorData?.description) {
      setDescription(visitorData.description);
      setInitialDescription(visitorData.description);
      setRemainingChars(80 - visitorData.description.length);
    }
  }, [visitorData]);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    setRemainingChars(80 - value.length);
    setIsSaveEnabled(value !== initialDescription);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await api.updateRegistryDetails(accessToken, registryId, { description: description });
      showToast("Description updated successfully", "success");
      fetchVisitorData();
      setLoading(false);
      setIsSaveEnabled(false);
      $("#description-modal").modal("hide");
    } catch (error) {
      showToast("Failed to update description", "error");
      console.log("Error while updating desccription", error);
    }
  };

  const handleCancel = async () => {
    $("#description-modal").modal("hide");
    setDescription(visitorData?.description);
  }

  return (
    <div className="modal fade" id="description-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>EDIT DESCRIPTION</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
          </div>
          <div className="modal-body login-box row">
            <div className="product-form mt-3">
              <form noValidate>
                <label className="mb-1">Description</label>
                <div className="user-box">
                  <textarea
                    id="description"
                    name="description"
                    placeholder="Add Description here..."
                    value={description}
                    onChange={handleDescriptionChange}
                    maxLength={80}
                  />
                  <div className="char-count">
                    {remainingChars} characters left
                  </div>
                </div>
              </form>
              <div className="user-detials-btn text-center mt-4 mb-3">
                <button type="button" className="common-modal-btn common-btn" onClick={handleCancel}>CANCEL</button>
                <button
                  type="button"
                  className="btn common-modal-btn common-btn active-btn"
                  onClick={handleSave}
                  disabled={!isSaveEnabled || loading}
                >
                  {loading ? "SAVING..." : "SAVE"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDescriptionModal;
