/* global $ */
import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

const BuyGiftModal = ({ productData }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isBuyButtonDisabled = !firstName || !lastName || !isEmailValid;

  const handleBuyGift = () => {
    window.open(productData.item_url, '_blank');
    navigate(`/purchase-process?itemId=${productData.id}&regId=${productData.registry_id}`);
  };

  const handleNotProvideInfo = () => {
    window.open(productData.item_url, '_blank');
    navigate(`/purchase-process?itemId=${productData.id}&regId=${productData.registry_id}`);
  };

  const handlePrivacyPolicy = () => {
    window.scrollTo(0, 0);
    $("#buy-product").modal("hide");
  }

  return (
    <div className="modal fade" id="buy-product" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body row">
            <div className="col-lg-12">
              <div className="product-form text-center">
                <div className="buy-gift-desc">
                  <h3>Before you buy this gift at {productData?.platform_name}</h3>
                  <p>Please enter your contact details below. We'll send you a reminder email with a link to update the registry.</p>
                </div>
                <form noValidate>
                  <div className="buy-gift-info">
                    <div className="user-box">
                      <input
                        type="text"
                        name="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <label>First Name</label>
                    </div>
                    <div className="user-box">
                      <input
                        type="text"
                        name="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                      <label>Last Name</label>
                    </div>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      name="Mail Id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label>Email</label>
                  </div>
                </form>
                <div className="buy-gift-note">
                  <p>We'll never share your info with any third parties.<br /><Link to="/privacy-policy" onClick={handlePrivacyPolicy}>PRIVACY POLICY</Link></p>
                </div>
                <div>
                  <button type="button" className="btn btn-primary form-btn main-btn" data-bs-dismiss="modal" onClick={handleBuyGift} disabled={isBuyButtonDisabled}>Buy Gift</button>
                </div>
                <div className="button-already primary-button text-center">
                  <button type="button" data-bs-dismiss="modal" onClick={handleNotProvideInfo}>I don't want to provide my info</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyGiftModal;
