import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { getRegistryCredentials } from '../../utils/registryUtils';
import api from "../../services/api";

const FindRegistryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fromMobileApp } = getRegistryCredentials();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);

  const dateQuestionMapping = {
    1: 3,
    2: 6,
    3: 7
  };

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  };

  const formatAnswerDate = (AnswerDate) => {
    if (!AnswerDate || !Date.parse(AnswerDate)) {
      return "";
    }
  
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(AnswerDate));
    return formattedDate;
  };

  const getRegistryEventDate = (registry) => {
    const questionId = dateQuestionMapping[registry.registry_type_id] || null;
    if (!questionId) return null;

    const dateAnswer = registry.user_answers.find(
      (answer) => answer.registry_question_id === questionId
    );

    return dateAnswer?.question_answer || null;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('q');
    if (query) {
      setSearchTerm(query);
    }
  }, [location.search]);

  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await api.searchRegistries(debouncedSearchTerm);
        if (response.message === "No matching records found.") {
          setSearchResults([]);
          setNoResults(true);
        } else {
          const results = response.data || [];
          setSearchResults(results);
          setNoResults(results.length === 0);
        }
      } catch (error) {
        console.error("Error searching registries:", error);
        setSearchResults([]);
      }
    }

    fetchSearchResults();
  }, [debouncedSearchTerm]);

  const handleViewRegistry = (registry) => {
    const registryUrl = `https://quickregistry.in/giftlist/${registry.public_key}`;
    
    if (fromMobileApp) {
      window.open(`/giftlist/${registry.public_key}`, '_self');
    } else {
      window.open(registryUrl, '_blank');
    }
  };

  const updateUrl = (term) => {
    if (term.trim() === "") {
      navigate("/search");
    } else {
      navigate(`/search?q=${encodeURIComponent(term)}`);
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value;

    if (value.startsWith(" ")) {
      value = value.trimStart();
    }

    value = value.replace(/\s{2,}/g, ' ');
    setSearchTerm(value);
    updateUrl(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="reset-password mb-1" id="find-registry">
                      <div className="reset-info text-center">
                        <h2 className="my-4">Find a Registry or Gift List</h2>
                      </div>
                      <form className="find-registry">
                        <div className="search-registry">
                          <input
                            type="text"
                            placeholder="Enter Name or Phone Number"
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                          />
                          <div className="search-icon">
                            <i className="la la-search reversed-icon"></i>
                          </div>
                        </div>
                        {searchResults.length === 0 && (
                          <>
                            {searchTerm.length < 4 && (
                              <p className="text-center no-result-msg">
                                To search, you must enter at least four characters
                              </p>
                            )}
                            {noResults && searchTerm.length >= 4 && (
                              <p className="text-center no-result-msg">
                                No registry found
                              </p>
                            )}
                          </>
                        )}
                      </form>
                      <div className="registry-table mt-5">
                        {searchResults.length > 0 && (
                          <div className="registry-header">
                            <div className="row header-row">
                              <div className="header-cell">Registrant</div>
                              <div className="header-cell">Registry Type</div>
                              <div className="header-cell">Location</div>
                              <div className="header-cell">Event Date</div>
                            </div>
                          </div>
                        )}
                        {searchResults.map((registry) => (
                          <div className="desc-info" key={registry.id}>
                            <Link to="#" className="registy-link" onClick={() => handleViewRegistry(registry)}>
                              <div className="desc-row">
                                <div className="registrant">
                                  <h5>
                                    {`${registry.user_first_name} ${registry.user_last_name}`}
                                      {registry.user_answers[1] && registry.user_answers[1].question_answer && (
                                        <span>
                                          {" & "}
                                          {registry.user_answers[1].question_answer}
                                        </span>
                                      )}
                                  </h5>
                                </div>
                                <div className="registry-type">
                                  <p><b>{registry.registry_type}</b></p>
                                </div>
                                <div className="location">
                                  <p><b>
                                    {registry.address?.city && registry.address?.state 
                                      ? `${registry.address.city}, ${registry.address.state}`
                                      : registry.address?.city || registry.address?.state}
                                  </b></p>
                                </div>
                                <div className="event-date">
                                  <p><b>{formatAnswerDate(getRegistryEventDate(registry))}</b></p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        {!fromMobileApp && <Footer />}
      </div>
    </div>
  );
};

export default FindRegistryPage;